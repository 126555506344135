<template>
  <section class="content-section">

    <div class="container shop px-4 px-lg-5">

      <div class="row" v-if="!$store.state.connected || $store.state.currentNetwork !== 2000">
        <div class="text-center">
          <span>Please connect to {{ $store.getters.config.chainName }}.</span><br/>
          <button class="btn btn-primary" v-on:click="$store.dispatch('connectWallet')">Connect wallet</button>
        </div>
      </div>

      <div class="row rats gx-5" v-if="$store.state.connected && $store.state.currentNetwork == 2000">

        <!-- Shop -->
        <!--div class="col-12">
          <h2 class="text-white mb-5 text-center">Special</h2>
        </div-->

        <!--div class="col-12 text-center xmas-bg-special">
          <div class="container">
            <div class="row">
              <span>
                It's Christmas Evening, you open the curtains, look out the window. A cozy, cold night that deserves a hot chocolate. With your favorite $RAT right in your windowsill, you see Santa flying through the sky. <br/>
              </span>
              <template v-for="shelve in $store.state.shelves" :key="shelve">
                <div class="col-12" v-if="shelve.id === 100007">
                  <div class="rat-shelve">
                    <img v-bind:src="'https://api.cryptor.at/nightmares/trait/99999_' + shelve.traitType" class="img-fluid rat-image" /><br/>
                    <span class="shop-text">
                      {{ shelve.metadata.name.split('#')[0] }} - 🪐 {{shelve.power}}<br/>
                      <template v-if="shelve.supply > 0">
                        Available: {{shelve.supply}}<br>
                      </template>
                      <template v-if="shelve.supply == 0">
                        <strong>Sold out! 💥</strong><br>
                      </template>
                      <button v-if="shelve.enabled && shelve.supply > 0" class="btn btn-primary" @click="buy(shelve)">Buy [{{shelve.normalisedCost}} WDOGE]</button>
                      <template v-if="$store.state.address === '0x967a4e8418de12ca89320313975be5e4f33a5c2d'"><br/>
                        <button v-if="shelve.enabled" class="btn btn-primary" @click="adminDisable(shelve)">Disable</button>&nbsp;
                        <button v-if="!shelve.enabled" class="btn btn-primary" @click="adminEnable(shelve)">Enable</button>&nbsp;
                        <br/>
                        <button class="btn btn-primary" @click="adminSetCost(shelve, '10')">10</button>&nbsp;
                        <button class="btn btn-primary" @click="adminSetCost(shelve, '20')">20</button>&nbsp;
                        <button class="btn btn-primary" @click="adminSetCost(shelve, '25')">25</button>&nbsp;
                        <button class="btn btn-primary" @click="adminSetCost(shelve, '30')">30</button>
                      </template>
                      <span v-if="shelve.bought">
                        <br/>Item bought! 🥳🎉
                      </span>
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div-->

        <div class="col-12">
          <h2 class="text-white mb-5 text-center">On the shelves</h2>
        </div>

        <div class="col-12 text-center">
          <a class="btn btn-primary" target="_blank" href="/files/Whitepaper-RatShop.pdf">
            Read our Shop Whitepaper
          </a>
        </div>

        <template v-for="shelve in $store.state.shelves" :key="shelve">
          <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 text-center rat-shelve" v-if="shelve.id < 9999 && (shelve.enabled || $store.state.address === '0x967a4e8418de12ca89320313975be5e4f33a5c2d')">
            <img v-bind:src="'https://api.cryptor.at/nightmares/trait/99999_' + shelve.traitType" class="img-fluid rat-image" /><br/>
            <span class="shop-text">
              {{ shelve.metadata.name.split('#')[0] }} - 🪐 {{shelve.power}}<br/>
              <template v-if="shelve.supply > 0">
                Available: {{shelve.supply}}<br>
              </template>
              <template v-if="shelve.supply == 0">
                <strong>Sold out! 💥</strong><br>
              </template>
              <button v-if="shelve.supply > 0 && shelve.enabled" class="btn btn-primary" @click="buy(shelve)">Buy [{{shelve.normalisedCost}} {{shelve.tokenName}}]</button>
              <template v-if="$store.state.address === '0x967a4e8418de12ca89320313975be5e4f33a5c2d'"><br/>
                {{shelve.id}}
                <button v-if="shelve.enabled" class="btn btn-primary" @click="adminDisable(shelve)">Disable</button>&nbsp;
                <button v-if="!shelve.enabled" class="btn btn-primary" @click="adminEnable(shelve)">Enable</button>&nbsp;
                <br/>
                <button class="btn btn-primary" @click="adminSetCost(shelve, '10')">10</button>&nbsp;
                <button class="btn btn-primary" @click="adminSetCost(shelve, '20')">20</button>&nbsp;
                <button class="btn btn-primary" @click="adminSetCost(shelve, '30')">30</button>
              </template>
              <template v-if="shelve.bought">
                <br/>Item bought! 🥳🎉
              </template>
            </span>
          </div>
        </template>

      </div>
    </div>
  </section>
</template>
<script>
import {ethers} from "ethers";
import erc20Abi from '../../../indexers/abi/erc20.json'

let party = function () {
  // Globals
  var random = Math.random
      , cos = Math.cos
      , sin = Math.sin
      , PI = Math.PI
      , PI2 = PI * 2
      , timer = undefined
      , frame = undefined
      , confetti = [];

  var spread = 40
      , sizeMin = 3
      , sizeMax = 12 - sizeMin
      , eccentricity = 10
      , deviation = 100
      , dxThetaMin = -.1
      , dxThetaMax = -dxThetaMin - dxThetaMin
      , dyMin = .13
      , dyMax = .18
      , dThetaMin = .4
      , dThetaMax = .7 - dThetaMin;

  var colorThemes = [
    function() {
      return color(200 * random()|0, 200 * random()|0, 200 * random()|0);
    }, function() {
      var black = 200 * random()|0; return color(200, black, black);
    }, function() {
      var black = 200 * random()|0; return color(black, 200, black);
    }, function() {
      var black = 200 * random()|0; return color(black, black, 200);
    }, function() {
      return color(200, 100, 200 * random()|0);
    }, function() {
      return color(200 * random()|0, 200, 200);
    }, function() {
      var black = 256 * random()|0; return color(black, black, black);
    }, function() {
      return colorThemes[random() < .5 ? 1 : 2]();
    }, function() {
      return colorThemes[random() < .5 ? 3 : 5]();
    }, function() {
      return colorThemes[random() < .5 ? 2 : 4]();
    }
  ];
  function color(r, g, b) {
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  // Cosine interpolation
  function interpolation(a, b, t) {
    return (1-cos(PI*t))/2 * (b-a) + a;
  }

  // Create a 1D Maximal Poisson Disc over [0, 1]
  var radius = 1/eccentricity, radius2 = radius+radius;
  function createPoisson() {
    // domain is the set of points which are still available to pick from
    // D = union{ [d_i, d_i+1] | i is even }
    var domain = [radius, 1-radius], measure = 1-radius2, spline = [0, 1];
    while (measure) {
      var dart = measure * random(), i, l, interval, a, b, c, d;

      // Find where dart lies
      for (i = 0, l = domain.length, measure = 0; i < l; i += 2) {
        a = domain[i], b = domain[i+1], interval = b-a;
        if (dart < measure+interval) {
          spline.push(dart += a-measure);
          break;
        }
        measure += interval;
      }
      c = dart-radius, d = dart+radius;

      // Update the domain
      for (i = domain.length-1; i > 0; i -= 2) {
        l = i-1, a = domain[l], b = domain[i];
        // c---d          c---d  Do nothing
        //   c-----d  c-----d    Move interior
        //   c--------------d    Delete interval
        //         c--d          Split interval
        //       a------b
        if (a >= c && a < d)
          if (b > d) domain[l] = d; // Move interior (Left case)
          else domain.splice(l, 2); // Delete interval
        else if (a < c && b > c)
          if (b <= d) domain[i] = c; // Move interior (Right case)
          else domain.splice(i, 0, c, d); // Split interval
      }

      // Re-measure the domain
      for (i = 0, l = domain.length, measure = 0; i < l; i += 2)
        measure += domain[i+1]-domain[i];
    }

    return spline.sort();
  }

  // Create the overarching container
  var container = document.createElement('div');
  container.style.position = 'fixed';
  container.style.top      = '0';
  container.style.left     = '0';
  container.style.width    = '100%';
  container.style.height   = '0';
  container.style.overflow = 'visible';
  container.style.zIndex   = '9999';

  // Confetto constructor
  function Confetto(theme) {
    this.frame = 0;
    this.outer = document.createElement('div');
    this.inner = document.createElement('div');
    this.outer.appendChild(this.inner);

    var outerStyle = this.outer.style, innerStyle = this.inner.style;
    outerStyle.position = 'absolute';
    outerStyle.width  = (sizeMin + sizeMax * random()) + 'px';
    outerStyle.height = (sizeMin + sizeMax * random()) + 'px';
    innerStyle.width  = '100%';
    innerStyle.height = '100%';
    innerStyle.backgroundColor = theme();

    outerStyle.perspective = '50px';
    outerStyle.transform = 'rotate(' + (360 * random()) + 'deg)';
    this.axis = 'rotate3D(' +
        cos(360 * random()) + ',' +
        cos(360 * random()) + ',0,';
    this.theta = 360 * random();
    this.dTheta = dThetaMin + dThetaMax * random();
    innerStyle.transform = this.axis + this.theta + 'deg)';

    this.x = window.innerWidth * random();
    this.y = -deviation;
    this.dx = sin(dxThetaMin + dxThetaMax * random());
    this.dy = dyMin + dyMax * random();
    outerStyle.left = this.x + 'px';
    outerStyle.top  = this.y + 'px';

    // Create the periodic spline
    this.splineX = createPoisson();
    this.splineY = [];
    for (var i = 1, l = this.splineX.length-1; i < l; ++i)
      this.splineY[i] = deviation * random();
    this.splineY[0] = this.splineY[l] = deviation * random();

    this.update = function(height, delta) {
      this.frame += delta;
      this.x += this.dx * delta;
      this.y += this.dy * delta;
      this.theta += this.dTheta * delta;

      // Compute spline and convert to polar
      var phi = this.frame % 7777 / 7777, i = 0, j = 1;
      while (phi >= this.splineX[j]) i = j++;
      var rho = interpolation(
          this.splineY[i],
          this.splineY[j],
          (phi-this.splineX[i]) / (this.splineX[j]-this.splineX[i])
      );
      phi *= PI2;

      outerStyle.left = this.x + rho * cos(phi) + 'px';
      outerStyle.top  = this.y + rho * sin(phi) + 'px';
      innerStyle.transform = this.axis + this.theta + 'deg)';
      return this.y > height+deviation;
    };
  }

  function poof() {
    if (!frame) {
      // Append the container
      document.body.appendChild(container);

      // Add confetti
      var theme = colorThemes[0];

      (function addConfetto() {
        var confetto = new Confetto(theme);
        confetti.push(confetto);
        container.appendChild(confetto.outer);
        timer = setTimeout(addConfetto, spread * random());
      })(0);

      // Start the loop
      var prev = undefined;
      var startTime = undefined;
      requestAnimationFrame(function loop(timestamp) {
        if(!startTime) {
          startTime = timestamp;
        }
        var delta = prev ? timestamp - prev : 0;
        prev = timestamp;
        var height = window.innerHeight;

        for (var i = confetti.length-1; i >= 0; --i) {
          if (confetti[i].update(height, delta)) {
            container.removeChild(confetti[i].outer);
            confetti.splice(i, 1);
          }
        }

        if(timestamp - startTime > 10000) {
          timer = undefined;
          confetti = [];
        }

        if ((timer || confetti.length))
            return frame = requestAnimationFrame(loop);

        // Cleanup
        document.body.removeChild(container);
        frame = undefined;
      });
    }
  }
  poof();
}

export default {
  watch: {
    '$store.state.shopContract': function() {
      this.$store.dispatch('loadShop');
    }
  },
  mounted() {
    this.$store.dispatch('loadShop');
  },
  methods: {
    party: () => {
      party();
    },
    buy: async function(shelve) {
      try {
        /**/
        if (shelve.token === ethers.constants.AddressZero) {
          await (await this.$store.getters.freshShopContract.mint(shelve.id, {value: shelve.cost})).wait();
        } else {
          let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
          let tokenContract = new ethers.Contract(shelve.token, erc20Abi, provider.getSigner())
          let allowance = await tokenContract.allowance(this.$store.state.address, '0xCD16daBdC8355FCa27ecdD0755fc0225cd81bf0B')
          let balance = await tokenContract.balanceOf(this.$store.state.address);
          if(balance.lt(shelve.cost)) {
            alert('Insufficient token balance.');
            return;
          }

          if(allowance.lt(shelve.cost)) {
            let tx = await tokenContract.approve('0xCD16daBdC8355FCa27ecdD0755fc0225cd81bf0B', ethers.constants.MaxUint256);
            await tx.wait();
          }
          await (await this.$store.getters.freshShopContract.mint(shelve.id)).wait();
        }
        /**/
        shelve.bought = true;
        party();
      } catch(e) {
        console.log(e);
      }
    },
    adminEnable: async function(shelve) {
      await this.$store.getters.freshShopContract.enableShelve(shelve.id, true);
    },
    adminDisable: async function(shelve) {
      await this.$store.getters.freshShopContract.enableShelve(shelve.id, false);
    },
    adminSetCost: async function(shelve, cost) {
      await this.$store.getters.freshShopContract.setCost(
          shelve.id,
          ethers.constants.AddressZero,
          ethers.utils.parseEther(cost));
    }
  }
}
</script>